import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
    apiKey: "AIzaSyB9Z7DSgddPs5xgU7eUvFMu-JNKSIBq1WE",
    authDomain: "evan-s-website.firebaseapp.com",
    projectId: "evan-s-website",
    storageBucket: "evan-s-website.appspot.com",
    messagingSenderId: "473486276452",
    appId: "1:473486276452:web:22e983ac71e586b5b46c71",
    measurementId: "G-GRTL997XCV"
  };
  
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage}