import '../styles/component_style/art_slider.css';
import Layout from './layout/Layout.tsx';
import GalleryDiv from './layout/GalleryDiv.tsx'
import { useRef, useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import {  ref, getDownloadURL } from 'firebase/storage';
import 'firebase/storage';
import { db, storage } from './firebaseConfig';
import Loading from './layout/loading.jsx';

const ArtSlider = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState([true])
  const itemRefs = useRef([]);

  useEffect(() => {
      const fetchImageUrls = async () => {
        const querySnapshot = await getDocs(collection(db, "paintings"));
        const docs = querySnapshot.docs.sort((a, b) => b.id - a.id);
        const urls = [];
        for (let doc of docs) {
          const storageRef = ref(storage, doc.data().url);
          const url = await getDownloadURL(storageRef);
          urls.push({url, year: doc.data().year});
        }
        setImageUrls(urls);
        setLoading(false)
      };
      fetchImageUrls();
    }, []);

    const scrollToYear = (year) => {
      const index = imageUrls.findIndex(image => image.year === year);
      if (index !== -1 && itemRefs.current[index]) {
          itemRefs.current[index].scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
  };

    return (
        <div id="container">
            <Layout id="layout" contentMaxWidth="100%">
            <GalleryDiv>
               {loading ? (
              <Loading className="loader"></Loading>
              ) : (
              imageUrls.map((imageUrl, index) => (
              <div 
                key={index} 
                ref={el => itemRefs.current[index] = el} 
                className='test'
                style={{ 
                  backgroundImage: `url(${imageUrl.url})`, 
                  backgroundSize: 'contain', 
                  backgroundRepeat: 'no-repeat', 
                  backgroundPosition: 'center' 
              }} 
        >
        </div>
    ))
)}
                </GalleryDiv>
            </Layout>
            <div id="button_container">
                <button className="button" onClick={() => scrollToYear(2024)}>2024</button>
                <button className="button" onClick={() => scrollToYear(2023)}>2023</button>
                <button className="button" onClick={() => scrollToYear(2022)}>2022</button>
                <button className="button" onClick={() => scrollToYear(2021)}>2021</button>
                <button className="button" onClick={() => scrollToYear(2020)}>2020</button>
                <button className="button" onClick={() => scrollToYear(2019)}>2019</button>    
            </div>
        </div>
    )
}

export default ArtSlider; 