import ArtSlider from "./components/ArtSlider.jsx";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="app">
        <header className="App-header">
          
        </header>
        <div className="app_container">
        <div className="right-side">
            <h1 className="title">Evan Casas</h1>
            <div className="plaque">
              <p className="lorem">
              As an artist, I’m not concerned with saying anything explicit. That is not to say my work is meaningless, but that each piece is a collaboration between me and the viewer who adds the meaning for themselves.
              <br></br><br></br>
              All I seek to do through art is depict humans—human interactions and the emotions and struggles that color people’s personalities and sense of self. I feel that art is the greatest way for me to connect with others—portraying what’s important to me and how I notice it. Each piece is created through the filter that is my perception and is an attempt to have an open conversation with the viewer. A quote from an interview with Francis Bacon in 1966 always swims around in my head when I draw;
              <br></br><br></br>
              “I think it’s how do I feel, and this can only be feeling—how can I make this image more immediately real to myself.”
              <br></br><br></br>
              I have yet to really accomplish anything I’ve set out to do with my art, but that's ok, because if I did already—there’d be no point in making.
              </p>
            </div>
            <div className="button_container">
            </div>
          </div>
          <div className="left-side">
            <Routes>
              <Route path="/" element={<ArtSlider />} />
            </Routes>
          </div>
          
          <div id="footer_format">
            <p>evancasaswf13@gmail.com</p>
            <p>© 2024 Evan Casas</p>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
