import '../../styles/component_style/loading.css'
import React, { useState, useEffect, useRef } from 'react';


const Loading = () => {
    const [frames, setFrames] = useState(Array(10).fill(0)); // Create an array of 10 frames
    const divRef = useRef(null);
    const symbols2 = ['-', '--', '---'];
    const symbols = ['Ñ', '@', '#', 'W', '$' ,'9', '8', '7', '6', '5', '4', '3', '2', '1', '0', '?', '!', 'a', 'b', 'c', ';', ':', '+', '=', '-', '.', '_'];

  useEffect(() => {
    const intervals = frames.map((_, i) => setInterval(() => {
      setFrames(prevFrames => prevFrames.map((frame, j) => (j === i ? (frame + 1) % symbols2.length : frame)));
    }, 80 * (i + 1))); // Each interval has a different delay

    return () => intervals.forEach(interval => clearInterval(interval)); 
  }, []);

  return (
      <div className="canvas" ref={divRef}>

        {frames.map(frame => (symbols2[frame] + ' ').repeat(1000)).join('')}

      </div>
  );
}

export default Loading;